<template>
  <v-container>

    <ConfirmDialog
        :is-open.sync="dialogs.setAbilityDialog.isOpened"
        :is-disable-confirm-button="!getDialogData('setAbilityDialog', 'abilityId')"
        width="400"
        confirm-button-text="Set"
        @confirm="setAbility"
    >
      <template v-slot:title>
        Select permission
      </template>
      <template v-slot:body>
        <v-row>
          for user:
        </v-row>
        <v-row class="font-weight-bold">
          {{ getDialogData('setAbilityDialog', 'userName') }}
        </v-row>
        <v-row>
          {{
            !getDialogData('setAbilityDialog', 'abilityId')
                ? '\xa0'
                : abilityDescription(getDialogData('setAbilityDialog', 'abilityId'))
          }}
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-autocomplete
                item-text="name"
                item-value="id"
                label="permissions"
                clearable
                dense
                chips
                small-chips
                v-model="dialogs.setAbilityDialog.data.abilityId"
                :items="abilities"
            />
          </v-col>
        </v-row>
      </template>
    </ConfirmDialog>

    <ConfirmDialog
        :is-open.sync="dialogs.removeAbilityDialog.isOpened"
        width="400"
        confirm-button-text="Remove"
        @confirm="removeAbility"
    >
      <template v-slot:title>
        Remove permission
        <span class="font-weight-bold pl-2">
          {{ getDialogData('removeAbilityDialog', 'abilityName') }}
        </span>
      </template>
      <template v-slot:body>
        <v-row>
          for user:
        </v-row>
        <v-row class="font-weight-bold">
          {{ getDialogData('removeAbilityDialog', 'userName') }}
        </v-row>
      </template>
    </ConfirmDialog>

    <ConfirmDialog
        :is-open.sync="dialogs.setCompositeCustomerDialog.isOpened"
        :is-disable-confirm-button="!getDialogData(
            'setCompositeCustomerDialog', 'compositeCustomerId'
        )"
        width="400"
        confirm-button-text="Set"
        @confirm="setCompositeCustomer"
    >
      <template v-slot:title>
        Select composite customers
      </template>
      <template v-slot:body>
        <v-row>
          for user:
        </v-row>
        <v-row class="font-weight-bold">
          {{ getDialogData('setCompositeCustomerDialog', 'userName') }}
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-autocomplete
                item-text="label"
                item-value="id"
                label="customers"
                clearable
                dense
                chips
                small-chips
                v-model="dialogs.setCompositeCustomerDialog.data.compositeCustomerId"
                :items="compositeCustomerList"
            />
          </v-col>
        </v-row>
      </template>
    </ConfirmDialog>

    <ConfirmDialog
        :is-open.sync="dialogs.removeCompositeCustomerDialog.isOpened"
        width="400"
        confirm-button-text="Remove"
        @confirm="removeCompositeCustomer"
    >
      <template v-slot:title>
        Remove customer
        <span class="font-weight-bold ml-4">
          {{ getDialogData('removeCompositeCustomerDialog', 'compositeCustomerName') }}
          </span>
      </template>
      <template v-slot:body>
        <v-row>
          for user:
        </v-row>
        <v-row class="font-weight-bold">
          {{ getDialogData('removeCompositeCustomerDialog', 'userName') }}
        </v-row>
      </template>
    </ConfirmDialog>

    <v-data-table
        :fixed-header="true"
        :headers="headers"
        :items="users"
        :loading="loading"
        loading-text="Loading... Please wait"
        v-resize="debounceResize"
        :height="tableHeight"
        :options.sync="options"
        :footer-props="{ itemsPerPageOptions: [50, 100, 200, 300, -1] }"
    >
      <template v-slot:top>
        <v-toolbar
            flat
            class="mt-4"
        >
          <ConfirmDialog
              :is-open.sync="dialogs.createUserDialog.isOpened"
              :is-disable-confirm-button="!getDialogData('createUserDialog', 'valid')"
              width="400"
              confirm-button-text="Add"
              @confirm="addUser"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  class="mx-2"
                  color="primary"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  fab
                  small
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <slot>
                      <v-icon
                          v-bind="attrs"
                          v-on="on"
                          dark
                      >
                        mdi-plus
                      </v-icon>
                    </slot>
                  </template>
                  create new User
                </v-tooltip>
              </v-btn>
            </template>
            <template v-slot:title>
              User
            </template>
            <template v-slot:body>
              <v-form v-model="dialogs.createUserDialog.data.valid">
                <v-container>
                  <v-row>
                    <v-col
                        cols="12"
                    >
                      <v-text-field
                          v-model="dialogs.createUserDialog.data.name"
                          label="Name"
                          :rules="requiredRules"
                      />
                    </v-col>
                    <v-col
                        cols="6"
                    >
                      <v-text-field
                          v-model="dialogs.createUserDialog.data.fp3d_login"
                          label="login"
                          :rules="requiredRules"
                      />
                    </v-col>
                    <v-col
                        cols="6"
                    >
                      <v-text-field
                          v-model="dialogs.createUserDialog.data.fp3d_user_id"
                          label="fp3d user id"
                      />
                    </v-col>
                    <v-col
                        cols="6"
                    >
                      <v-text-field
                          v-model="dialogs.createUserDialog.data.password"
                          label="password"
                          :rules="requiredRules"
                      />
                    </v-col>
                    <v-col
                        cols="6"
                    >
                      <v-text-field
                          v-model="dialogs.createUserDialog.data.confirm_password"
                          label="confirm password"
                          :rules="requiredRules"
                      />
                    </v-col>
                    <v-col>
                      <v-autocomplete
                          v-model="dialogs.createUserDialog.data.abilities"
                          :items="abilities"
                          item-text="name"
                          item-value="id"
                          label="permissions"
                          multiple
                          clearable
                          dense
                          chips
                          small-chips
                          :menu-props="{ closeOnContentClick: true }"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </template>
          </ConfirmDialog>

          <ConfirmDialog
              :is-open.sync="dialogs.changePasswordDialog.isOpened"
              :is-disable-confirm-button="!getDialogData('changePasswordDialog', 'valid')"
              width="500"
              confirm-button-text="Add"
              @confirm="changePassword"
          >
            <template v-slot:title>
              Change password
            </template>
            <template v-slot:body>
              <div>User ID: {{ getDialogData('changePasswordDialog','user_id') }}</div>
              <v-form v-model="dialogs.changePasswordDialog.data.valid">
                <v-container>
                  <v-row>
                    <v-col
                        cols="6"
                    >
                      <v-text-field
                          v-model="dialogs.changePasswordDialog.data.password"
                          label="password"
                          :rules="requiredRules"
                      />
                    </v-col>
                    <v-col
                        cols="6"
                    >
                      <v-text-field
                          v-model="dialogs.changePasswordDialog.data.confirm_password"
                          label="confirm password"
                          :rules="requiredRules"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </template>
          </ConfirmDialog>

          <ConfirmDialog
              :is-open.sync="dialogs.personalApiTokenDialog.isOpened"
              dialog-max-width="600px"
              confirm-button-text="generate"
              @confirm="generatePersonalApiToken"
          >
            <template v-slot:title>
              User personal API token
            </template>
            <template v-slot:body>
              <v-row>
                <div>User ID: {{ getDialogData('personalApiTokenDialog','user_id') }}</div>
              </v-row>
              <v-row
                  align="center"
                  justify="center"
              >
                <div>{{ getDialogData('personalApiTokenDialog','personal_api_token') }}</div>
                <v-btn
                  icon
                  small
                  @click="copyToClipboard(
                      getDialogData('personalApiTokenDialog','personal_api_token')
                      )"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                      <v-icon
                          v-bind="attrs"
                          v-on="on"
                      >
                        mdi-clipboard-outline
                      </v-icon>
                  </template>
                  copy to clipboard
                </v-tooltip>
              </v-btn>
              </v-row>
            </template>
          </ConfirmDialog>

        </v-toolbar>
      </template>

      <template v-slot:item.name="{ item }">
        {{ item.name }}
        <v-icon v-if="item.id===user.id"
                x-small
                color="orange"
        >
          mdi-star
        </v-icon>
      </template>

      <template v-slot:item.tripbits_customer_user="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
                v-if="item.tripbits_customer_user"
                color="green"
                v-bind="attrs"
                v-on="on"
            >
              mdi-account-check
            </v-icon>
            <v-icon
                v-else
                color="gray"
                v-bind="attrs"
                v-on="on"
            >
              mdi-account-cancel
            </v-icon>
          </template>
          {{
            item.tripbits_customer_user
                ? 'customer user is bound to tripbits' : 'customer user is not bound to tripbits'
          }}
        </v-tooltip>
      </template>

      <template v-slot:item.abilities="{ item }">
        <v-btn
            text
            color="teal accent-4"
            fab
            small
            @click="openDialog('setAbilityDialog', { userId: item.id, userName: item.name })"
        >
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <slot>
                <v-icon
                    v-bind="attrs"
                    v-on="on"
                >
                  mdi-label
                </v-icon>
              </slot>
            </template>
            add permission
          </v-tooltip>
        </v-btn>
        <v-chip
            v-for="ability in item.abilities"
            :key="ability.id"
            :close="!item.tripbits_customer_user || !ability.name === $constants.ABILITY.CUSTOMER"
            @click:close="openDialog(
                'removeAbilityDialog',
                {
                  userId: item.id,
                  userName: item.name,
                  abilityId: ability.id,
                  abilityName: ability.name,
                })"
            class="ma-0"
            dark
            small
        >
          {{ ability.name }}
        </v-chip>
      </template>

      <template v-slot:item.composite_customers="{ item }">
        <div
            v-if="hasCustomerAbility(item.abilities)"
        >
          <v-btn
              text
              color="teal accent-4"
              fab
              small
              @click="openDialog(
                  'setCompositeCustomerDialog',
                  {
                    userId: item.id,
                    userName: item.name,
              }) "
          >
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                    v-bind="attrs"
                    v-on="on"
                >
                  mdi-label
                </v-icon>
              </template>
              add  composite customer
            </v-tooltip>
          </v-btn>
          <v-tooltip
              v-for="compositeCustomer in item.composite_customers"
              :key="compositeCustomer.id"
              bottom
          >
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                  v-bind="attrs"
                  v-on="on"
                  close
                  @click:close="openDialog(
                      'removeCompositeCustomerDialog', {
                        userId: item.id,
                        userName: item.name,
                        compositeCustomerId: compositeCustomer.id,
                        compositeCustomerName: compositeCustomer.name
                      })"
                  class="ma-0"
                  :color="compositeCustomer.has_airport_customer ? '#FF6E40' : '#c49c18'"
                  small
              > <span>
                {{ compositeCustomer.name }}
                {{ compositeCustomer.has_airport_customer ? '(has apt)' : '' }}
                </span>
              </v-chip>
            </template>
            {{ compositeCustomer.customers
              .map((el)=> `${el.name}${el.is_airport ? ' (apt)' : ''}`).join(', ') }}
          </v-tooltip>
        </div>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-btn
            icon
            @click="openDialog(
              'personalApiTokenDialog',
              { user_id: item.id, personal_api_token: item.personal_api_token },
            )"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <slot>
                <v-icon
                    v-bind="attrs"
                    v-on="on"
                    :color="item.personal_api_token !== null && item.personal_api_token.length
                     ? 'info'
                     : 'error'"
                >
                  mdi-account-key
                </v-icon>
              </slot>
            </template>
            {{ item.personal_api_token !== null && item.personal_api_token.length
                ? 'manage personal API token'
                : 'generate personal API token'
            }}
          </v-tooltip>
        </v-btn>

        <v-btn
            icon
            @click="openDialog('changePasswordDialog', { user_id: item.id })"
        >

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <slot>
                <v-icon
                    v-bind="attrs"
                    v-on="on"
                    dark
                >
                  mdi-key
                </v-icon>
              </slot>
            </template>
            change password
          </v-tooltip>
        </v-btn>

        <v-btn
            icon
            @click="remove(item.id)"
            :disabled="item.tripbits_customer_user"
        >
          <v-icon
              color="red"
          >
            mdi-delete
          </v-icon>
        </v-btn>
      </template>

    </v-data-table>
  </v-container>
</template>

<script>
import debounce from 'lodash.debounce';
import { mapGetters, mapActions } from 'vuex';
import ConfirmDialog from '../components/dialogs/ConfirmDialog.vue';
import manageDialogsMixin from '../mixins/manageDialogsMixin';
import copyToClipboard from '../mixins/copiToClipboard';

export default {
  name: 'UserManagement',
  components: {
    ConfirmDialog,
  },

  mixins: [
    manageDialogsMixin,
    copyToClipboard,
  ],

  data: () => ({
    abilities: [],
    users: [],
    tableHeight: null,
    loading: true,
    options: {
      itemsPerPage: 50,
      sortBy: ['id'],
      sortDesc: [false],
      mustSort: true,
      multiSort: false,
    },

    dialogs: {
      setAbilityDialog: {
        isOpened: false,
        data: {
          abilityId: null,
          userId: null,
          userName: null,
        },
        defaults: {
          abilityId: null,
          userId: null,
          userName: null,
        },
      },

      removeAbilityDialog: {
        isOpened: false,
        data: {
          abilityId: null,
          abilityName: null,
          userId: null,
          userName: null,
        },
        defaults: {
          abilityId: null,
          abilityName: null,
          userId: null,
          userName: null,
        },
      },

      setCompositeCustomerDialog: {
        isOpened: false,
        data: {
          compositeCustomerId: null,
          userId: null,
          userName: null,
        },
        defaults: {
          compositeCustomerId: null,
          userId: null,
          userName: null,
        },
      },

      removeCompositeCustomerDialog: {
        isOpened: false,
        data: {
          compositeCustomerId: null,
          compositeCustomerName: null,
          userId: null,
          userName: null,
        },
        defaults: {
          compositeCustomerId: null,
          compositeCustomerName: null,
          userId: null,
          userName: null,
        },
      },

      createUserDialog: {
        isOpened: false,
        data: {
          valid: false,
          name: '',
          fp3d_login: '',
          fp3d_user_id: '',
          password: '',
          confirm_password: '',
          abilities: null,
        },
        defaults: {
          valid: false,
          name: '',
          fp3d_login: '',
          fp3d_user_id: '',
          password: '',
          confirm_password: '',
          abilities: null,
        },
      },

      changePasswordDialog: {
        isOpened: false,
        data: {
          user_id: null,
          password: '',
          confirm_password: '',
        },
        defaults: {
          user_id: null,
          password: '',
          confirm_password: '',
        },
      },

      personalApiTokenDialog: {
        isOpened: false,
        data: {
          user_id: null,
          personal_api_token: '',
        },
        defaults: {
          user_id: null,
          personal_api_token: '',
        },
      },
    },

    requiredRules: [
      (v) => !!v || 'Field is required',
    ],

    headers: [
      { text: 'id', value: 'id', width: '80px' },
      { text: 'name', value: 'name', width: '200px' },
      { text: 'login', value: 'fp3d_login', width: '150px' },
      {
        text: 'permissions', value: 'abilities', width: '300px', sortable: false,
      },
      {
        text: 'composite customers', value: 'composite_customers', width: '300px', sortable: false,
      },
      {
        text: 'tripbits customer user', value: 'tripbits_customer_user', width: '200px', sortable: false,
      },
      { text: 'fp3d_user', value: 'fp3d_user_id', width: '100px' },
      { text: 'actions', value: 'actions', width: '150px' },
    ],

    compositeCustomerList: [],
  }),

  created() {
    this.getUsers();
    this.getCompositeCustomers();

    this.$requestHelpers.get('api/admin/ability')
      .then((response) => {
        this.abilities = response.data;
        this.loading = false;
      });
  },

  mounted() {
    this.onResize();
  },

  methods: {
    ...mapActions({
      setSnack: 'snackbar/setState',
    }),

    onResize() {
      this.tableHeight = window.innerHeight - 240;
    },

    getUsers() {
      this.$requestHelpers.get('api/admin/user')
        .then((response) => {
          this.users = response.data;
          this.loading = false;
        }).catch((error) => {
          if (error.response) {
            if (error.response.status === 403) {
              this.$router.push({ name: 'root' });
            }
          }
        });
    },

    getCompositeCustomers() {
      this.$requestHelpers.get('api/admin/compositeCustomer')
        .then((response) => {
          this.compositeCustomerList = response.data.map(
            (el) => ({
              id: el.id,
              name: el.name,
              label: (el.has_airport_customer
                ? `${el.name} - has airport`
                : el.name),
            }),
          );
        });
    },

    removeAbility() {
      if (!this.getDialogData('removeAbilityDialog', 'abilityId')) return;

      this.$requestHelpers.post('api/admin/user/removeAbility',
        {
          user_id: this.getDialogData('removeAbilityDialog', 'userId'),
          ability_id: this.getDialogData('removeAbilityDialog', 'abilityId'),
        }).then((response) => {
        const userId = this.users.findIndex((item) => item.id === this.getDialogData('removeAbilityDialog', 'userId'));

        this.users[userId].abilities = response.data.abilities;

        this.setSnack({
          color: 'green',
          text: 'permission was removed',
        });
      }).finally(() => {
        this.closeDialog('removeAbilityDialog');
      });
    },

    setAbility() {
      if (!this.getDialogData('setAbilityDialog', 'abilityId')) return;

      this.$requestHelpers.post('api/admin/user/setAbility',
        {
          user_id: this.getDialogData('setAbilityDialog', 'userId'),
          ability_id: this.getDialogData('setAbilityDialog', 'abilityId'),
        }).then((response) => {
        const userId = this.users.findIndex((item) => item.id === this.getDialogData('setAbilityDialog', 'userId'));

        this.users[userId].abilities = response.data.abilities;

        this.setSnack({
          color: 'green',
          text: 'permission was set',
        });
      }).catch(() => {
        this.setSnack({
          snack: true,
          color: 'red',
          text: 'error',
        });
      }).finally(() => {
        this.closeDialog('setAbilityDialog');
      });
    },

    removeCompositeCustomer() {
      if (!this.getDialogData('removeCompositeCustomerDialog', 'compositeCustomerId')) return;

      this.$requestHelpers.post('api/admin/user/removeCompositeCustomer',
        {
          user_id: this.getDialogData('removeCompositeCustomerDialog', 'userId'),
          composite_customer_id: this.getDialogData('removeCompositeCustomerDialog', 'compositeCustomerId'),
        }).then((response) => {
        const user = this.users
          .find((item) => item.id === this.getDialogData('removeCompositeCustomerDialog', 'userId'));

        Object.assign(user, response.data);

        this.setSnack({
          color: 'green',
          text: 'composer customer was removed',
        });
      }).finally(() => {
        this.closeDialog('removeCompositeCustomerDialog');
      });
    },

    setCompositeCustomer() {
      if (!this.getDialogData('setCompositeCustomerDialog', 'compositeCustomerId')) return;

      this.$requestHelpers.post('api/admin/user/setCompositeCustomer',
        {
          user_id: this.getDialogData('setCompositeCustomerDialog', 'userId'),
          composite_customer_id: this.getDialogData('setCompositeCustomerDialog', 'compositeCustomerId'),
        }).then((response) => {
        const user = this.users
          .find((item) => item.id === this.getDialogData('setCompositeCustomerDialog', 'userId'));

        Object.assign(user, response.data);

        this.setSnack({
          color: 'green',
          text: 'composite customer was added',
        });
      }).finally(() => {
        this.closeDialog('setCompositeCustomerDialog');
      });
    },

    addUser() {
      this.$requestHelpers.post('api/admin/user', this.dialogs.createUserDialog.data)
        .then(() => {
          this.getUsers();
        }).finally(() => {
          this.closeDialog('createUserDialog');
        });
    },

    changePassword() {
      this.$confirm('Are you sure you want to change the password to this user?').then((res) => {
        if (!res) return;

        this.$requestHelpers.post('api/admin/user/change-password', this.dialogs.changePasswordDialog.data)
          .finally(() => {
            this.closeDialog('changePasswordDialog');
          });
      });
    },

    generatePersonalApiToken() {
      this.$confirm('Are you sure you want to generate the personal API token to this user?').then((res) => {
        if (!res) return;

        this.$requestHelpers.post(
          'api/admin/user/generatePersonalApiToken',
          {
            user_id: this.getDialogData('personalApiTokenDialog', 'user_id'),
          },
        ).then((response) => {
          const newToken = response.data.personal_api_token;
          const user = this.users
            .find((item) => item.id === this.getDialogData('personalApiTokenDialog', 'user_id'));

          this.dialogs.personalApiTokenDialog.data.personal_api_token = newToken;
          Object.assign(user, response.data);

          this.setSnack({
            color: 'green',
            text: 'personal API token has been updated',
          });
        });
      });
    },

    remove(id) {
      this.$confirm('Are you sure you want to delete this user?').then((res) => {
        if (!res) return;

        this.$requestHelpers.delete(`api/admin/user/${id}`)
          .then(() => {
            this.getUsers();
          });
      });
    },
  },

  computed: {
    ...mapGetters({
      user: 'auth/user',
      userAbilities: 'auth/abilities',
    }),
    debounceResize() {
      return debounce(this.onResize, 100);
    },

    abilityDescription() {
      return (abilityId) => this.abilities.find((el) => el.id === abilityId).description;
    },

    hasCustomerAbility() {
      return (abilities) => abilities.findIndex((el) => el.name === 'customer') >= 0;
    },
  },
};

</script>
