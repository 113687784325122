const copyToClipboard = {
  methods: {
    async copyToClipboard(text) {
      try {
        await navigator.clipboard.writeText(text);

        await this.$store.dispatch('snackbar/setState', {
          text: 'Token has been copied to clipboard',
          color: 'green',
        });
      } catch (err) {
        await this.$store.dispatch('snackbar/setState', {
          text: 'Error during token copy',
          color: 'error',
        });
      }
    },
  },
};

export default copyToClipboard;
