<template>
  <v-dialog
    v-model="isOpened"
    :max-width="dialogMaxWidth"
    @click:outside="cancel"
    @keydown.esc="cancel"
    @keydown.enter="confirm"
    :persistent="persistent"
  >
    <template v-slot:activator="{ on, attrs }">
      <slot name="activator" :on="on" :attrs="attrs" />
    </template>
    <v-card>
      <v-card-title>
        <slot name="title" />
      </v-card-title>
      <v-card-text>
        <slot name="alert" />
        <v-container>
          <slot name="body" />
        </v-container>
      </v-card-text>
      <v-card-actions
        v-if="isActionButtonEnabled"
      >
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          text
          @click="cancel(false)"
        >
          Cancel
        </v-btn>
        <v-btn
          v-if="showConfirmButton"
          @click="confirm"
          :disabled="isDisableConfirmButton"
          color="blue darken-1"
          text
        >
          {{ confirmButtonText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

</template>

<script>

export default {
  name: 'ConfirmDialog',
  props: {
    showConfirmButton: {
      type: Boolean,
      default: true,
    },
    confirmButtonText: {
      type: String,
      default: 'Ok',
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
    isDisableConfirmButton: {
      type: Boolean,
      default: false,
    },
    isActionButtonEnabled: {
      type: Boolean,
      default: true,
    },
    dialogMaxWidth: {
      type: String,
      default: '500px',
    },
    persistent: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    cancel(isUsePersistent = true) {
      if (isUsePersistent && this.persistent) {
        return;
      }

      this.isOpened = false;
    },

    confirm() {
      if (this.isDisableConfirmButton) {
        return false;
      }

      this.$emit('confirm');

      return true;
    },
  },

  computed: {
    isOpened: {
      get() {
        return this.isOpen;
      },

      set(val) {
        this.$emit('update:isOpen', val);
      },
    },
  },

};
</script>
